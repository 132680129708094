<template>
    <v-img :src="appBarImage()" max-height="300px" class="app-bar">
        <v-app-bar
            id="app-bar"
            absolute
            app
            color="transparent"
            flat
            :class="{'pt-10': $helpers.isMobile(), 'pa-10': !$helpers.isMobile()}"
            style="left: 0;"
            height="75"
        >

            <v-icon class="mr-4" color="white" @click="$store.state.drawer = !$store.state.drawer">mdi-menu</v-icon>
            <router-link :to="{name: 'home'}">
                <v-img :src="$store.getters.settings.logo_w || ''" :width="$helpers.isMobile() ? '100px' : '150px'"></v-img>
            </router-link>

            <v-spacer/>

            <div class="mx-3" v-if="!$helpers.isMobile()"/>

            <div v-if="$store.getters.secondUser">
                <v-btn
                    class="ml-2"
                    min-width="0"
                    text
                    @click="backToAdmin"
                >
                    <span class="white--text text-caption">Admin</span> <v-icon color="white" small class="ml-2">mdi-logout</v-icon>
                </v-btn>
            </div>

            <div v-if="!$store.getters.secondUser">
                <v-btn
                    :class="{ 'ml-2' : !$helpers.isMobile(), 'pr-0' : $helpers.isMobile() }"
                    min-width="0"
                    text
                    @click="logout"
                    :loading="logouting"
                    :disabled="logouting"
                >
                    <span class="white--text text-caption">{{ $t('logout') }}</span> <v-icon color="white" small class="ml-2">mdi-logout</v-icon>
                </v-btn>
            </div>

            <v-menu
                bottom
                left
                offset-y
                origin="top right"
                transition="scale-transition"
                v-if="!$helpers.isMobile()"
            >
                <template v-slot:activator="{ attrs, on }">
                    <v-btn
                        min-width="0"
                        text
                        v-bind="attrs"
                        v-on="on"
                        @click="$router.push({name: 'profileNotifications'})"
                    >
<!--                      <v-badge v-if="notifications.length"-->
<!--                          color="green"-->
<!--                          :content="notifications.length"-->
<!--                      >-->
                        <v-icon color="white" small>mdi-bell</v-icon>
<!--                      </v-badge>-->
                    </v-btn>
                </template>

<!--                <v-list-->
<!--                    :tile="false"-->
<!--                    nav-->
<!--                >-->
<!--                    <div>-->
<!--                        <app-bar-item-->
<!--                            v-for="(n, i) in notifications"-->
<!--                            :key="`item-${i}`"-->
<!--                            @click="$router.push({name: n.code})"-->
<!--                        >-->
<!--                            <v-list-item-title @click="$router.push({name: n.code})" v-text="n.content" />-->
<!--                        </app-bar-item>-->
<!--                    </div>-->
<!--                </v-list>-->
            </v-menu>

            <!-- <v-btn
                min-width="0"
                text
                v-if="!$helpers.isMobile()"
            >
                <v-icon color="white" small>mdi-cog</v-icon>
            </v-btn> -->

            <v-btn
                class="ml-2"
                min-width="0"
                text
                @click="$router.push({name: 'profile'})"
            >
                <v-avatar size="48" color="white">
                    <v-img :src="$store.getters.user.user.avatar ? $store.getters.user.user.avatar+'?'+ Date.now() : '/assets/img/avatar.png'">
                        <template v-slot:placeholder>
                            <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                            >
                            <v-progress-circular
                                indeterminate
                                color="primary"
                            ></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </v-avatar>
                <div v-if="!$helpers.isMobile()" class="ml-5 text-body-1 white--text">{{ $store.getters.user.user.firstname }} {{ $store.getters.user.user.name }}</div>
            </v-btn>
        </v-app-bar>
    </v-img>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations } from "vuex";

export default {
    name: "DashboardCoreAppBar",

    components: {
        AppBarItem: {
            render(h) {
                return h(VHover, {
                    scopedSlots: {
                        default: ({ hover }) => {
                            return h(
                                VListItem,
                                {
                                    attrs: this.$attrs,
                                    class: {
                                        "black--text": !hover,
                                        "white--text primary elevation-12": hover
                                    },
                                    props: {
                                        activeClass: "",
                                        dark: hover,
                                        link: true,
                                        ...this.$attrs
                                    }
                                },
                                this.$slots.default
                            );
                        }
                    }
                });
            }
        }
    },

    props: {
        value: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        notifications: [],
        logouting: false
    }),

    computed: {
        ...mapState(["drawer"])
    },

    mounted(){
        this.getLastNotifications()
    },

    methods: {
        getLastNotifications(){
            this.$store.dispatch('getLastNotifications')
            .then(resp => {
                this.notifications = resp
            })
        },
        appBarImage(){
            switch (this.$route.name) {
                // case 'tresor':
                //     return '/assets/img/dashboard/app-banner.png'
                default:
                    return `/assets/img/dashboard/app-banner${Math.floor(Math.random() * (3 - 1 + 1) ) + 1}.png`
            }
        },

        ...mapMutations({
            setDrawer: "SET_DRAWER"
        }),

        logout(){
            this.logouting = true
            this.$store.dispatch('logout')
            .then(resp => {
                this.$router.push({name: 'home'})
            })
            .catch(err => {
                this.logouting = false
                this.$helpers.showError(err)
            })
        },

        backToAdmin(){
            this.$store.commit('clear_second_user')
            window.location = this.$store.getters.frontUrl + '/admin/users/list'
        }
    }
};
</script>
